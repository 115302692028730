'use client';

import { lazy } from 'react';
import { addPage, sendAppClientDataEvent, useSelector } from '@/core';
import { useTrackPageTypeView } from '@/utils/analytics';
import { PageHeaderSizes } from '@knapsack/types';
import { useKsNav } from '@/utils/routes';
import { HOMEPAGE_PAGE_ID } from '@/utils/constants';

const CustomPage = lazy(() => import('./pages/[pageId]/page'));

const HomePage = () => {
  useTrackPageTypeView({
    pageName: 'Home',
  });
  const { title, hasHomepage } = useSelector((s) => {
    return {
      title: s.db.settings.title,
      hasHomepage: Object.values(s.customPagesState.pages).find(
        (page) => page.id === HOMEPAGE_PAGE_ID,
      ),
    };
  });
  const { goTo } = useKsNav();

  function createHomePage() {
    sendAppClientDataEvent([
      addPage({
        id: HOMEPAGE_PAGE_ID,
        title,
        skipNav: true,
        parentId: 'root',
      }),
      {
        type: 'pagesOrPatterns.settings.pageHeader.update' as const,
        id: HOMEPAGE_PAGE_ID,
        idType: 'page',
        pageHeader: {
          backgroundColor: {
            colorValue: '#6A4CEC',
            type: 'value',
          },
          textColorTitle: {
            colorValue: '#FFFFFF',
            type: 'value',
          },
          textColorDescription: {
            colorValue: '#FFFFFF',
            type: 'value',
          },
          size: PageHeaderSizes.large,
        },
      },
    ]);
    setTimeout(() => {
      goTo('/');
    }, 100);
  }

  if (hasHomepage) {
    return (
      <CustomPage
        key={HOMEPAGE_PAGE_ID}
        params={{ pageId: HOMEPAGE_PAGE_ID }}
      />
    );
  }
  createHomePage();
};

export default HomePage;
